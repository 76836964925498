/* --------------------- Variables ------------------ */
:root {
  --gradientPrimary: linear-gradient(93.72deg, #00B3D6 -35.92%, #15CCF0 78.99%);
  --primary: #00B3D6;
  --ds-link-primary-color: #00B3D6;
  --secondary: #67E0A6;
  --white: #ffffff;
  --danger: #dc3545;
  --black: #091133;
  --tableText: #8A7C8D;
  --grey: #979797;
  --primaryLight: #F0FBFD;
  --tableShadow: 0px 3px 15px 0px #00B3D61F;
  --btnShadow: 0px 2px 8px 0px #0000001A;
  --borderColor: #E6E6E6;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins" !important;
}



/* ------------------------- Override Css ------------------------ */
button {
  cursor: pointer !important;
}

.btn {
  padding: 10px 24px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}

.btn-primary {
  background: var(--gradientPrimary) !important;
  border: none !important;
}

.btn-danger {
  background-color: var(--danger);
  color: var(--white);
  border: 1px solid var(--danger);
}

.btn-danger:hover {
  background-color: #c72f3e;
}

.btn-primary:hover {
  background: var(--primary) !important;
  border: none !important;
}

.btn-outline-primary {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  background-color: var(--white) !important;
  padding-block: 9px;
}

.btn-light {
  box-shadow: var(--btnShadow);
  color: var(--primary) !important;
  background-color: var(--white) !important;
  border: none !important;
}

.btn-light:hover {
  box-shadow: none;
}

.btn-outline-primary:hover {
  box-shadow: var(--btnShadow);
}

.border {
  border: 1px solid var(--borderColor) !important;
}

.border-top {
  border-top: 1px solid var(--borderColor) !important;
}

.border-bottom {
  border-bottom: 1px solid var(--borderColor) !important;
}

.img-fluid {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
}

.mt-auto {
  margin-top: auto;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-center {
  text-align: center;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-secondary {
  color: var(--tableText) !important;
}

.text-grey {
  color: var(--grey) !important;
}

::-webkit-scrollbar {
  background: var(--borderColor);
  height: 6px;
  width: 6px;
  border-radius: 50px;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

::-webkit-scrollbar-thumb {
  background: #8A7C8D;
  height: 5px;
  border-radius: 50px;
}

.m-0 {
  margin: 0 !important;
}

.ms-1 {
  margin-left: 4px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  margin-top: 0;
  font-weight: 500;
}

p {
  font-weight: 400;
  margin: 0;
  color: var(--black);
}


/* ------------------------ New CSS ----------------------- */
.accordian-btn {
  box-shadow: var(--btnShadow);
  padding: 14px;
  background-color: var(--white);
  border-radius: 5px;
  color: var(--primary);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.submenu {
  padding-left: 16px;
  padding-top: 10px;
}

.hide .submenu {
  padding-left: 11px;
  padding-top: 0px;
}

.common-banner {
  height: 178px;
  position: sticky;
  top: 0;
  z-index: 1;
  min-height: 178px;
  display: flex;
  align-items: center;
  background: url('./components/assets/banner.png') center/cover no-repeat;
  justify-content: center;
}

.common-banner h3 {
  font-size: 30px;
}

.common-banner span {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.common-card {
  box-shadow: var(--tableShadow);
  border-radius: 15px;
  padding: 35px 30px;
  background-color: var(--white);
}

.container {
  padding: 20px;
}

.form-control {
  border: 1px solid var(--borderColor) !important;
  outline: none;
  font-size: 14px !important;
  color: var(--black);
  border-radius: 4px;
  padding-inline: 8px;
}

.form-control:hover {
  border-color: var(--primary) !important;
}

.form-control:focus {
  border-color: var(--primary) !important;
}

::placeholder {
  font-size: 14px;
  color: var(--tableText);
}

select {
  padding: 10px 40px 10px 10px;
  border: 1px solid var(--borderColor);
  outline: none;
  background-color: var(--white);
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('./components/assets/Vector.svg');
  background-position: right .75rem center;
  background-repeat: no-repeat;
  color: var(--black);
  cursor: pointer;
}

.Toastify__close-button {
  width: fit-content !important;
  background-color: transparent !important;
}

/* ------------------------ Loader CSS Start ----------------------- */
.loader-container {
  height: 100vh;
  display: flex;
  align-items: center;
  position: fixed;
  inset: 0;
  margin: auto;
  width: 100%;
  z-index: 2;
  background-color: var(--white);
  justify-content: center;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: var(--primary);
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em
  }

  40% {
    box-shadow: 0 2.5em 0 0
  }
}

/* ------------------------ Loader CSS End ----------------------- */
.auth-logo {
  text-align: center;
  margin-bottom: 24px;
}

.toggle-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-bottom: 6px;
  background-color: transparent !important;
  border: 1px solid var(--primary);
  /* position: absolute; */
  /* right: 6px;
  top: 6px; */
  outline: 0;
}

.hide .toggle-btn {
  /* left: 0;
  right: 0; */
  margin-bottom: 10px;
  margin-inline: auto;
}

.charts {
  padding: 20px;
}

.chart-section {
  margin-bottom: 24px;
}

.chart-section h3 {
  margin-bottom: 16px;
}

.chart-section p {
  color: #8A7C8D;
  margin-bottom: 24px;
}

.chart-section small {
  color: #8A7C8D;
}

.filter-section {
  display: flex;
  justify-content: end;
  margin: 24px;
  align-items: center;
  gap: 12px;
}
.chart-section .table-responsive {
  box-shadow: none !important;
}