.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 30px 16px;
    background-color: var(--primaryLight);
    overflow: auto;
}

.login-box {
    background-color: white;
    border-radius: 16px;
    padding: 24px 40px;
    box-shadow: var(--tableShadow);
    width: 500px;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

h1 {
    text-align: center;
    margin-bottom: 30px;
}

.form-group {
    margin-bottom: 20px;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.login-button {
    width: 100%;
    padding: 12px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #45a049;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 20px;

}

.alternative-options {
    text-align: center;
    margin-top: 20px;
}

.google-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285f4;
    color: white;
    border: none;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
}

.google-login-button img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    border-radius: 50%;
}


.forgot-password-link {
    display: inline-block;
    margin-top: 10px;
    color: var(--primary);
    text-decoration: none;
    font-size: 14px;
}

.forgot-password-link:hover {
    text-decoration: underline;
}

.signup-option {
    text-align: center;
    margin-top: 20px;
}

.signup-option p {
    margin-bottom: 10px;
    color: #333;
}

.signup-link {
    color: var(--primary);
    text-decoration: none;
    font-size: 16px;
}

.signup-link:hover {
    text-decoration: underline;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.login-button {
    padding: 10px 15px;
}

.separator {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
    color: #888;
}

.separator span {
    display: inline-block;
    padding: 0 10px;
    background-color: #fff;
}


.logout-button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
}

.separator {
    text-align: center;
    margin: 10px 0;
}