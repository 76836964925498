@import './index.css';

@font-face {
  font-family: 'Poppins';
  src: url('./components/assets/Poppins/Poppins-Regular.ttf') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./components/assets/Poppins/Poppins-Bold.ttf') format('woff2');
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.app-container {
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #f5f5f5;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 9999;
}

.login-container input {
  display: block;
  width: 100%;
  padding: 10px;
}

button {
  padding: 10px;
  width: 100%;
  background-color: #6200ea;
  color: #fff;
  border: none;
}

button:hover {
  background-color: #3700b3;
}

/* Sidebar container styling */
.sidebar {
  width: 250px;
  background-color: #2c3e50;
  height: 100%;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
}

/* User details section */
.user-details h3 {
  margin-bottom: 10px;
}

.user-details p {
  margin: 5px 0;
  font-size: 14px;
}

.logout-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e74c3c;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.logout-button:hover {
  background-color: #c0392b;
}


.sidebar {
  transition: 0.2s;
  width: 250px;
  background-color: var(--white);
  border-right: 1px solid var(--borderColor);
}

.hide {
  width: 60px;
  transition: 0.2s;
}

.hide .sidebar-menu-inner>div {
  padding: 10px;
}

.hide .accordian-btn {
  padding: 6px;
}

.hide .btn-primary:hover {
  background: unset !important;
  border: 1px solid var(--primary) !important;
}

.hide .sidebar-menu ul li:hover {
  background-color: unset !important;
}

/* Sidebar menu section */
.sidebar-menu {
  height: 100%;
  position: relative;
}

.sidebar-menu-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-menu-inner>div {
  padding: 20px;
}

.sidebar-menu ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-menu ul li {
  display: flex;
  align-items: center;
  padding: 13px 0;
  gap: 8px;
  cursor: pointer;
}

.sidebar-menu ul li .icon {
  color: var(--grey);
}

.sidebar-menu ul li span {
  font-size: 14px;
  color: var(--grey);
  word-break: break-all;
}

/* Hover effect for menu items */
.sidebar-menu ul li:hover span {
  color: var(--primary);
  transition: 0.3s;
}

.sidebar-menu ul li:hover svg path {
  fill: var(--primary);
  transition: 0.3s;
}

.sidebar-menu ul li:hover .icon {
  color: var(--primary);
  transition: 0.3s;
}

.logout {
  padding: 20px !important;
  border-top: 1px solid var(--borderColor);
}

.logout:hover svg path {
  fill: unset !important;
  stroke: var(--primary) !important;
}

/* Badge styling for new items */
.new-badge {
  background-color: #e74c3c;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-left: auto;
  margin-right: 10px;
}

/* Add this to your App.css or a specific Sidebar.css file */
.sidebar-header {
  padding-bottom: 20px;
}

.sidebar-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.sidebar-menu ul li.active span {
  color: var(--primary);
  line-height: 0;
}

.sidebar-menu ul li.active .icon {
  color: var(--primary);
}

.sidebar-menu ul li.active svg path {
  fill: var(--primary);
}

.tab-container {
  padding: 20px;
}

.tabs {
  display: flex;
  box-shadow: var(--btnShadow);
  justify-content: space-between;
  border-radius: 6px;
  margin-bottom: 20px;
  overflow: hidden;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  font-weight: 500;
  background-color: var(--white) !important;
  cursor: pointer;
  font-size: 16px;
  color: var(--primary);
  font-family: "Poppins", serif !important;
  font-size: 14px;
  border-radius: 0;
  width: 50%;
}

.tabs button.active {
  background-color: var(--primary) !important;
  color: #fff;
}

.tab-content {
  padding: 20px;
}

.profile-tab p,
.sales-channel-tab p {
  font-size: 16px;
  margin: 10px 0;
}

.sales-channel-tab p {
  text-align: center;
  margin: 0;
}

.connect-button {
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.connect-button:hover {
  background-color: #cc5200;
}

.main {
  display: flex;
  height: 100vh;
}

.main-content {
  flex-grow: 1;
  width: calc(100% - 250px);
  padding-bottom: 20px;
  height: 100%;
  overflow-y: auto;
  background-color: var(--white);
}

.dashboard-sections {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 20px;
}

.dashboard-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 18.6%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
}

.dashboard-card h2 {
  font-size: 18px;
  color: var(--black);
  margin-bottom: 6px;
}

.dashboard-card p {
  font-size: 20px;
  color: var(--tableText);
}

.dashboard-icon {
  font-size: 40px;
  color: var(--primary);
  margin-bottom: 14px;
}

@media (max-width: 1199.98px) {
  .dashboard-card {
    width: 25%;
  }
}

@media (max-width: 991.98px) {
  .dashboard-card {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .dashboard-card {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .dashboard-card {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--white);
  box-shadow: var(--btnShadow);
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 6px;
}

.user-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.user-name {
  font-size: 18px;
  color: var(--black);
  font-weight: 500;
}

.options-menu {
  position: absolute;
  right: 20px;
  top: 60px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: var(--tableShadow);
  z-index: 10;
}

.option {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f0f0f0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.user-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.user-name {
  font-size: 18px;
  color: var(--black);
  font-weight: 500;
}

.arrow-icon {
  margin-left: 5px;
  transition: transform 0.2s;
}

.arrow-icon.up {
  transform: rotate(180deg);
}

.options-menu {
  position: absolute;
  right: 0;
  top: 60px;
  background-color: white;
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  box-shadow: var(--tableShadow);
  z-index: 10;
  min-width: 200px;
}

.user-details {
  padding: 10px 15px;
  border-bottom: 1px solid var(--borderColor);
}

.user-name {
  margin: 0;
  color: var(--black);
  font-weight: 500;
}

.user-email {
  margin: 0;
  color: #666;
}

.option {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f0f0f0;
}

.plans-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.plan-card {
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  margin-inline: auto;
  background-color: var(--white);
  box-shadow: var(--btnShadow);
  transition: transform 0.2s;
}

.plan-card:hover {
  transform: scale(1.02);
}

.plan-card h2 {
  margin: 0;
  margin-bottom: 16px;
}

.plan-card .plan-description {
  margin: 10px 0;
}

.plan-card ul {
  list-style-type: disc;
  padding-left: 20px;
}

.plan-card p {
  margin: 5px 0;
  color: var(--tableText);
  font-weight: 500;
  font-size: 14px;
}

.plan-card li {
  margin-bottom: 10px;
  font-size: 14px;
}

.plan-card button {
  margin-top: 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.plan-card button:hover {
  background-color: #0056b3;
}

.transactions-list {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto;
  justify-content: center;
}

.transaction-item {
  border: 1px solid var(--borderColor);
  flex: 0 0 auto;
  padding: 12px 24px;
  border-radius: 5px;
  box-shadow: var(--btnShadow);
  min-width: 200px;
  text-align: center;
  margin-bottom: 10px;
}

.transaction-item p {
  font-size: 14px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.pagination button {
  background-color: var(--primary);
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 14px;
}

.sidebar-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 200px;
  height: 100%;
  background-color: #f9f9f9;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

/* Styling the user section */
.sidebar-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  margin-top: auto;
}

.user-icon {
  background-color: var(--primary);
  color: white;
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 10px;
}

.user-name {
  font-weight: 500;
  font-size: 16px;
}

.user-email {
  font-size: 14px;
}

/* Modal overlay and positioning */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Modal content styling */
.modal-content {
  background-color: white;
  padding: 30px;
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
}

.maximize-modal {
  height: 100vh;
  width: 100%;
  max-width: unset;
  max-height: unset;
  border-radius: 0;
}

/* Scrollbar styling for modal */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Close button */
.close-button {
  position: absolute;
  top: 11px;
  right: 10px;
  background-color: var(--borderColor) !important;
  color: var(--black);
  border: none;
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  padding: 0;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: darkred;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  caption-side: bottom;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.table-responsive {
  box-shadow: var(--tableShadow);
  border-radius: 15px;
  overflow-x: auto;
}

table>thead>tr>th {
  background-color: var(--primaryLight);
  font-size: 12px;
  font-weight: 500;
  color: var(--black);
  width: 25%;
}

table>tbody>tr>td {
  text-wrap: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: var(--tableText);
}

th,
td {
  border-bottom: 1px solid #F5F3F5;
  padding: 16px !important;
}

th {
  border: none !important;
}

table>thead>tr>th:first-child {
  border-radius: 15px 0 0 0;
}

table>thead>tr>th:last-child {
  border-radius: 0 15px 0 0;
}



.earnings-overview {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  font-family: "Poppins", serif !important;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.earnings-overview h2 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 0;
  color: #333;
}

.earnings-blocks {
  display: flex;
  justify-content: space-around;
  gap: 15px;
}

.earnings-block {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.earnings-block:hover {
  transform: translateY(-5px);
}

.earnings-block h3 {
  font-size: 18px;
  margin-top: 0;
  color: var(--primary);
  margin-bottom: 10px;
}

.earnings-block p {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.sales-table {
  width: 100%;
  border-collapse: collapse;
}

.sales-table th,
.sales-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.sales-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.extension-settings {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
}

.settings-field {
  margin-bottom: 16px;
}

.settings-field label {
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
  color: var(--black);
}

label {
  font-weight: 500;
}

.settings-field input {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: var(--primary);
  color: #fff;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

/* loader 2 */

.loader2 {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: var(--primary);
  position: relative;
}

.loader2::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin-block: auto;
  bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid var(--primary);
  border-bottom-color: #FF3D00;
  box-sizing: border-box;
  animation: rotation 0.6s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.min-max-btn {
  width: 30px;
  height: 30px;
  background-color: var(--borderColor) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 11px;
  right: 50px;
}

/* Add this to your CSS */
.chart-container {
  width: 100%;
  overflow-x: auto;
  padding: 10px;
}

.bar-chart {
  min-width: 1000px;
}